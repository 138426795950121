/* FONT PATH
 * -------------------------- */

@font-face {
    font-family: 'FontAwesome';
    src: url('#{$fa-font-path}/font-awesome/fontawesome-webfont.eot?v=#{$fa-version}');
    src: url('#{$fa-font-path}/font-awesome/fontawesome-webfont.eot?#iefix&v=#{$fa-version}')
            format('embedded-opentype'),
        url('#{$fa-font-path}/font-awesome/fontawesome-webfont.woff2?v=#{$fa-version}')
            format('woff2'),
        url('#{$fa-font-path}/font-awesome/fontawesome-webfont.woff?v=#{$fa-version}')
            format('woff'),
        url('#{$fa-font-path}/font-awesome/fontawesome-webfont.ttf?v=#{$fa-version}')
            format('truetype'),
        url('#{$fa-font-path}/font-awesome/fontawesome-webfont.svg?v=#{$fa-version}#fontawesomeregular')
            format('svg');

    font-weight: normal;
    font-style: normal;
}
