/* Login page logo */


.logo-login-light {
    background-color: white !important;
    width: 110px;
    padding: 0px 5px;
    margin-left: 21px;
}


.logo {
    background-color: black;
    padding: 1em;
    margin-bottom: 0px !important;
}

.dashboard-logo {
    padding-left: 0px !important;
    position: relative;
}

.bg-green {
    background: #01b051; 
    }

.toggled-logo {
    width : 90px;
}

/* Drop-zone image uploader */

.dropzone-custom-multiple {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1em;
}
.dropzone-custom-single {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 1em;
}
.inputLabelWithFiles-custom {
    position: relative;
    margin: 1em 1em 1em 1em;
    height: 170px;
    width: 170px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.555) !important;
    opacity: 0;
    animation: forwards fadeIn 1s;
    animation-delay: 1s;
    cursor: pointer;
    box-shadow: $common-box-shadow;
}
.preview-image {
    position: relative;
    height: 170px;
    width: 170px;
    border-radius: 10px;
    margin: 1em;
    cursor: pointer;
    img {
        border-radius: 10px;
    }
}

.close-icon {
    position: absolute;
    color: rgb(236, 81, 81);
    border-radius: 100%;
    background-color: $white;
    right: 0;
    transform: translate(5px, -10px);
    &:hover {
        border: 2px solid rgb(230, 136, 136);
    }
}

.add-image-icon {
    height: 170px;
    width: 170px;
    border-radius: 10px;
    color: $primary-color;
}
.header-wrapper {
    padding: 2.4em;
}
.noPadding {
    padding: 0 !important;
}
.noMargin {
    margin: 0 i !important;
}


// step form header
.stepForm-header {
    position: unset;
    display: flex;
    height: 32px;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 5em;
}
.stepForm-header-icon {
    position: unset;
    display: block;
    border: 2px solid gray;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-active {
        position: unset;
        display: block;
        border: 2px solid rgb(248, 192, 154);
        height: 32px;
        width: 32px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

     &-checked {
    position: unset;
    display: block;
    border: 2px solid rgb(12, 212, 12);
    height: 32px;
    width: 32px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
     }
}

.stepForm-header-line {
    display: block;
    width: 250px;
    height: 4px;
    border: 3px solid gra;
    background-color: gray;
    &:last-child {
        display: none;
    }
    &-checked {
        display: block;
        width: 250px;
        height: 4px;
        border: 3px solid green;
        background-color: green;
        &:last-child {
            display: none;
        }
    }
}

.check-icon {
    height: 24px;
    width: 24px;
    color: green;
}

.step-name {
    position: absolute;
    top: 133px;
    margin-left: -2%;
    color: gray;
    &-active {
        position: absolute;
    top: 133px;
    margin-left: -2%;
        color: $primary-color;
    }
}

.p-us {
    position: unset;
}

.circle {
    display: block;
    background-color: $primary-color;
    border-radius: 100%;
    height: 0.5em;
    width: 0.5em;
}

.hashloader-container {
    width: 100%;
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

//loader
.loader-logo {
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
}

//image-gallery
.image-figure {
    cursor: pointer;
    object-fit: cover;
    height: 211px;
    width: 364px;
}
.cross-button {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

     position: absolute;
     right:0;
      background-color: white;
      border:2px solid white;
      padding: 3px;
      border-radius: 50%;
      transition: all 0.2s linear;
      &:hover {
            scale: 1.2;
            border-color: red;
            color: white;
      }
}

.widget-holder {
    display: flex;
    flex-direction: row;
    width: 100%;
}

//Add Product

.addProduct-card {
    padding-top: 1rem;

    .table-responsive {
        button.btn-action {
            padding: 0.375rem 0.75rem;
            background-color: transparent;
        }
    }
}

.centered-box-fh{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 100px) !important;

}


.input {
    &-danger {
        border-color: red;
        :focus {
            border-color: red;
        }
    }
}

.edit-profile {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toggle-sidebar {
    svg {
        color:white !important;
        width: 19px;
    }
}

.sidebar_login{
    height: 100vh;
    // background-color: red !important;
    display: flex;
    align-items: center;
    background-color: white;
    justify-content: center;
}
.sidebar_login img{
    width: 100%;
}

// //wysiwyg editor

// .public-DraftEditor-content {
//     padding: 1em;
// }
// .action-icon {
//     font-size: 20px;
//     color: red;
//     transition: all 0.3s ease;
//     cursor: pointer;
//     &:hover {
//         scale: 1.2;
//     }
// }

// // .multi-selector .ant-input, .multi-selector .ant-cascader-picker{
// //     border:none !important;
// // }

// .no-border{
//     border:none  !important;
// }

// .ant-cascader-menu{
//     min-width: 250px !important;
// }

// //variant image modal

// .modal-header {
//     padding: 1em;

// }

// .fullpage-gallery{
//     width: 100% !important;
//     max-width: 100%;
// }

// .image-gallery {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
// }

// .variant-gallery {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     height: 47em !important;
//     min-height: 47em;;
//     overflow-y: hidden;
//     overflow: scroll;
// }
// .modal-dialog {
//     max-width: 80% !important;
// }


// .selected {
//     border: 3px solid $primary-color;
// }

// img.selected{
//     filter: contrast(.4)
// }

// .bg-gray{
//     background-color: #ccc;
// }

.img-thumbnail {
    object-fit: cover;
}

.card {
    border-radius: 0px !important;
}

.description {
    &-long {
        height: 30em;
        .public-DraftEditor-content, .DraftEditor-editorContainer {
            height: 20em !important;
            overflow: scroll;
            &:first-child {
                height: 20em !important;
            }
        }
    }
    &-short{
        height: 25em;
        .public-DraftEditor-content, .DraftEditor-editorContainer{
            height: 15em !important;
            overflow: scroll;
            &:first-child {
                height: 15em !important;
            }
        }
    }


}


.wizi-editor {
    height: 30em !important;
}
