.page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon div.sidebar-wrapper .sidebar-main .sidebar-links li a span, .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
    color: white;
}

.page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon div.sidebar-wrapper .sidebar-main .sidebar-links > li .sidebar-link.active .according-menu i{
    color: $white;
}


.page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon div.sidebar-wrapper .sidebar-main .sidebar-links > li .sidebar-link .according-menu i{
    color: $white;
}

.page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon div.sidebar-wrapper .sidebar-main .sidebar-links > li .sidebar-link.active span{
    color:white
}

.page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon div.sidebar-wrapper .sidebar-main .sidebar-links > li .sidebar-link.active{
    color: white;
}


.page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon div.sidebar-wrapper .sidebar-main .sidebar-links > li .sidebar-link.active svg{
    color:white;
}

.page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon div.sidebar-wrapper .sidebar-main .sidebar-links > li .sidebar-link.active{
    margin-bottom: auto;
}

.react-datepicker{
    width: 29em;
position: absolute;
left: -120px;
}

.hYa-DIp{
    padding: 0px !important
}
.gghHAJ{
    padding: 0px !important

}

.page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon div.sidebar-wrapper{
    background: #474747;
    padding-top: 10px;
}