// From Font Awesome
.#{$mdi-css-prefix}-spin:before {
    -webkit-animation: #{$mdi-css-prefix}-spin 2s infinite linear;
    animation: #{$mdi-css-prefix}-spin 2s infinite linear;
}

@-webkit-keyframes #{$mdi-css-prefix}-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes #{$mdi-css-prefix}-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
