.main-detail {
    display: flex;
    gap: 4rem;
    font-family: 'Poppins', sans-serif;


    .productDetail {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .detail {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
        }
    }

    .ant-image-img {
        width: 350px !important;
        min-width: 100px !important;
        border: 0.5px solid grey;
    }

    .reviewPart{
        display: flex;
        flex-direction: column;
        width:100%;
       
        h5{
            width: fit-content;
            padding-bottom: 4px;
            border-bottom: 0.7px solid gainsboro;
        }
    }
}

@media screen and (max-width:695px){
    .main-detail{
        flex-direction: column;
        gap: 1rem;
    }
    .productDetail{
        flex-direction: row !important;
        gap: 10px;
    }

}

.user-review{
    max-width: 100%;
    display: flex;
    flex-direction: column;

    .user-info{
        display: flex;
        flex-direction: row;
        gap: 0.5rem
    }
    .message{
        display: flex;
        justify-content: space-between;
       label{
          font-family: 'Poppins', sans-serif;
          font-size: 0.9rem;
       } 
    }
}

@media screen and (max-width:1034px){
    .message{
        flex-direction: column;
        justify-content: space-between;

    }
    .ant-rate {
        width: fit-content !important;
    }
    
}



@media screen and (max-width:632px) {
    .productDetail {
        flex-direction: column !important;
        gap: 15px;
    }

}