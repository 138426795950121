.details {
    display: flex;
    justify-content: space-between;
    padding: 2rem;

}

.order-details--text {
    display: flex;
}

.order-details--text p:first-child {
    display: flex;
    padding: 0px 10px;
}

.order-details--text p {
    display: flex;
    margin: 0;
}

.order-details--text p:nth-child(2) {
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.customer-details>p {
    font-weight: bold;
}

.customer-details>p {
    margin: 0;
}

.select-label {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.select-label>label {
    font-weight: bold;
}

.flex-end {
    display: flex;
    justify-content: end;
}

.flex-end--filter {
    display: flex;
    justify-content: end;
    margin: 10px 0px;
}

.btn {
    color: white;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    margin: 2px;
    background-color: #01b051 !important;
}

.btn:hover {
    background-color: rgb(4,122,58) !important;
    color: white;
}

.input {
    max-width: 150px;
    margin: 2px;


}

/* .ant-input:hover, .ant-input:focus-within{
    /* border: 1px solid red !important; */

/* }  */

/* .ant-picker:hover, .ant-picker:focus-within{
    border: 1px solid red !important;

} */

// .ant-select-selector:hover,
// .ant-select-selector:focus-within {
//     border: 1px solid red !important;
// }

.ant-picker-today-btn {
    color: red !important;
}

.ant-picker-cell-today .ant-picker-cell-inner {
    border: 2px solid red !important;

}


.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: #01b051 !important;

}


.flex-row {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin: 20px;
}

.flex-column {
    display: flex;
    flex-direction: column;
    margin: 5px;
    
}

.bold {
    font-weight: bold;
}



// @media (max-width: 420px) {
//     .details {
//         flex-direction: column;
//         align-items: center;
//     }

//     .customer-details {
//         border-bottom: 2px solid;
//         padding-bottom: 10px;
//     }


// }

.ant-btn {
    // padding: 5px;
    border-radius: 5px;
    background-color: transparent;
    border-color: red;
}


.order-body {
    // display: flex;
    // gap: 2rem;
    font-family: 'Poppins', sans-serif;
    // .card{
    //     background-color: #fff !important;
    //     border-radius: 10px !important;
    //     // margin : 0 5px;
    // }
    .trackCode{
        color: rgb(167, 165, 165);
    }
    .orderID{
        color: rgb(167, 165, 165);
    }
    .orderTime{
        color: rgb(158, 154, 154);
    }
   
    // .ant-timeline-item.active{
        // svg{
        //     // animation: pulse 2s infinite !important;
           
        // }
    // }

}
// @keyframes pulse{
//     from{
//          opacity:1 !important;
//          transform: scale(3) !important;
//      }
//      to{
//          opacity:0.1 !important;
//          transform:scale(0.1) !important;
//      }
//  }

.buttonOrder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.iddiv{
    cursor: pointer;
}
.custDetails{
    font-family: 'Poppins',sans-serif;
}

.invoice{

    font-family: 'Poppins',san-serif;
    .invoiceHeader{
        font-size: 20px;
        font-weight: 500;
    }
}