
.effect-ripple {
    @include addRipple($primary-color);
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    background-color: $primary-color;
    animation: ripple 0.7s linear infinite;
}


.fade-in-anim {
    animation: fadeIn 0.4s;
}
.fade-out-anim {
    animation: fadeOut 0.1s;
}