﻿@function char($character-code) {
    @if function-exists('selector-append') {
        @return unquote('"\\#{$character-code}"');
    }

    @if "\\#{'x'}"== '\\x' {
        @return str-slice('\x', 1, 1) + $character-code;
    } @else {
        @return #{'"\\'}#{$character-code + '"'};
    }
}

@function mdi($name) {
    @if map-has-key($mdi-icons, $name) == false {
        @warn "Icon #{$name} not found.";
        @return '';
    }
    @return char(map-get($mdi-icons, $name));
}
