.main {
    border-radius: 0px;
    height: 7em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
};

.form-input {
    width: 100%;
}

.form {
    padding: 1em;
}

.no-radius{
    border-radius: 0px !important;
}


.filter-products {

    .ant-picker-cell-today .ant-picker-cell-inner {
        border: 0;
    }


    .ant {
        &-form-vertical {
            .ant-form-item {
                width: 100%;
                
                @media (min-width: 1199px){
                    margin-bottom: 0;
                }
            }
        }

        &-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
            border-color: rgba($primary-color, .5);

        }

        &-select {

            &:not(.ant-select-disabled):hover,
            &-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
                .ant-select-selector {
                    border-color: rgba($primary-color, .5);
                }
            }

            @media (max-width: 767px){
                margin-bottom: 20px;
            }
        }

        &-picker {

            &:hover {
                border-color: rgba($primary-color, .5);
            }

            &-focused {
                border-color: rgba($primary-color, .5);
            }

            &-range {
                .ant-picker-active-bar {
                    border-color: rgba($primary-color, .5);
                }
            }
        }

        &-divider-horizontal {
            @media (max-width: 767px){
                margin: 0 0 20px;
            }
        }

        &-checkbox-checked {
            &:hover {
                border-color: rgba(1,176,81, .5);
            }

        .ant-checkbox-inner {
            background-color: rgba(1,176,81, 1);
            border-color: rgba(1,176,81, 1);
        }
        }

        &-checkbox-input:focus + .ant-checkbox-inner {
            border-color: rgba(1,176,81, .5);
        }

        
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: rgba($primary-color, .5);
    }

    &__checkbox {
        flex-wrap: wrap;
        display: flex;

        li {
            padding: 27px 15px 0 0;

            @media (max-width: 991px){
                flex: 0 0 33.33%;
                max-width: 33.33%;
                padding: 0 15px 0 0;
            }

            @media (max-width: 575px){
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0 0 5px;
            }

            // &:last-child{
            //     // padding: 0;

            //     @media (max-width: 991px){
            //         flex: 0 0 100%;
            //         max-width: 100%;
            //         padding: 15px 0 0;
            //     }

            //     @media (max-widht: 575px){
            //         padding: 0;
            //     }
            // }
        }


    }

    .search-products {

        input {
            &:hover,
            &:focus-within {
                border: 0 !important;
            }
        }
    }

}

.ant-divider-horizontal{
    margin : 12px 0px !important;
}



// .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner{
//     &::before {
//         border-color: rgba(1,176,81, .5);
//         }
//     }
